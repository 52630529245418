import { useState } from 'react';
import register_api from "./api/register_api"
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

function RegistrationForm(Email_sent={Email_sent}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [message, setMessage] = useState("");

  const success = async (text)=> {
    console.log("Yeah! Authenticated!");
    console.log(text);
    // await localStorage.setItem("userToken", text.access);
    // window.location = "/index/register/email_sent/";
    Email_sent.Email_sent()
  };

  const failed = async (text)=> {
    console.log(text);
    setMessage(text)
    // await localStorage.setItem("userToken", text.access);
    // window.location = "/index/register/email_sent/";
  };

  const tryRegister = async (e) => {
    e.preventDefault();
    if (ConfirmPassword === password & password !== ""){
        console.log("Register with", email, FirstName, LastName);
        register_api(email, password, FirstName, LastName, success, failed)
    }else{
        // alert("your passwords did not match or is empty, pls try again")
        toast('your passwords did not match or is empty, pls try again', {
          duration: 4000,
          position: 'top-center',
          
          // Styling
          style: {},
          className: '',
        
          // Custom Icon
          icon: '☹️',
        
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: '#000',
            secondary: '#fff',
          },
        
          // Aria
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        });
    }
    
  };
  

  return ( 
    <div id="regForm_overlay">
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#7f77e0',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      
      <form id="regForm" onSubmit={tryRegister}>
        <div className="inputTextOverlay">
          <p>First name</p> 
          <input className="input_form" autoFocus type="text" id="FirstName" placeholder="John" onChange={(e)=>{setFirstName(e.target.value)}} value={FirstName}/>
        </div>
        
        <div className="inputTextOverlay">
        <p>Last name</p>
          <input className="input_form" type="text" id="LastName" placeholder="Robert" onChange={(e)=>{setLastName(e.target.value)}} value={LastName}/>
        </div>
        
        <div className="inputTextOverlay">
        <p>E-mail</p>
          <input className="input_form" type="text" id="email" placeholder="my_mail@mail.com" onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} value={email} required/>
        </div>
        
        <div className="inputTextOverlay">
        <p>Password</p>
          <input className="input_form" type="password" id="password" placeholder="************" onChange={(e)=>{setPassword(e.target.value)}} value={password} required/>
        </div>
        
        <div className="inputTextOverlay">
        <p>Confirm Password</p>
          <input className="input_form" type="password" id="confirm_password" placeholder="************" onChange={(e)=>{setConfirmPassword(e.target.value)}} value={ConfirmPassword} required/>
        </div>
        

        <div style={{margin: "1em", color: "red"}}>{message}</div>

        <div id="RegSubmitBTN_overlay">
                <button id="submit" type="submit" value="Register">REGISTER</button>
        </div>
      </form>
      <p>Already have an account?</p>
        <Link to="/login" >
          <button id="loginBTN">login</button>
        </Link>
    </div>
    
  );
}

export default RegistrationForm