import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import login_api from "./components/api/login_api"
import './Login.css'
import toast, { Toaster } from 'react-hot-toast';
import { clear_nav, ledger_nav } from '../defualt_Layouts/slices/navSlice'
import { ledger_mobileNav, clear_mobileNav } from '../defualt_Layouts/slices/navSliceMobile'
import { useSelector, useDispatch } from 'react-redux'

function Login() {
  // const [refresh, setRefresh] = useRefresh
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(clear_nav())
      dispatch(clear_mobileNav())
    },[]
  )

  const success = async (text, path)=> {
    console.log("You are logged in!");
    await localStorage.setItem("refreshToken", text.refresh);
    await localStorage.setItem("accessToken", text.access);

    const currentMobileDisplayValue = document.getElementsByClassName('mobile')
    navigate(path);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    console.log("Loggin in with", email);
    login_api("/ledger", email, password, success, (text)=>{setMessage(text)})
  };

  return (
        
    <section>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#7f77e0',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />

      <div id="loginOverlay">
        <div id="loginTitle">
          <h1>Login</h1>
        </div>

        <div id="loginForm_overlay">
          <form id="loginForm" onSubmit={formSubmit}>
              <div className="inputTextOverlay">
                <p>E-mail</p> 
                <input className="input_form" autoFocus type="email" id="email" placeholder="my_mail@mail.com" onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} value={email} required/>
              </div>
              
              <div className="inputTextOverlay">
                <p>Password</p>
                <input className="input_form" type="password" id="password" placeholder="************" onChange={(e)=>{setPassword(e.target.value)}} value={password} required/>
              </div>
              
              
              <div style={{margin: "1em", color: "red"}}>{message}</div>

              <p>Forgot your password? <span>Click Here</span></p>
              
              <div id="submitBTN_overlay">
                <button className='desktop submit' type="submit" value="Login">LOG IN</button>
                <button className='mobile submit' type="submit" value="Login">LOG IN</button>
              </div> 
              
          </form>

          <p>You don't have an account?</p>

          <Link to="/registration" id='registerBTN_Overlay'>
            <button id="registerBTN">REGISTER</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
 
export default Login